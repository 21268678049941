import request from '@/utils/http'

/**
 * 图片库分类
 * @param payload
 */
export function group_list(payload) {
    return request({ url: '/Storage/groupList', method: 'post', data: payload })
}

/**
 * 图片相册
 * @param payload
 */
export function photo(payload) {
    return request({ url: '/Storage/photo', method: 'post', data: payload })
}

/**
 * 上传图片
 * @param payload
 */
export let upload = process.env.VUE_APP_API_BASE_URL + '/Storage/uploadImage';